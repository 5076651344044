<template>
  <XButton
    border-radius="circle"
    size="sm"
    design="text"
    :class="[$style.updates, hasUpdates && $style.newUpdate]"
    @click="openChangelog"
  >
    <div class="changelog" />
    <div :class="$style.icon">
      <XIcon
        size="lg"
        icon="gift"
      />
    </div>
  </XButton>
</template>

<script>
import { defineComponent, onMounted, onUnmounted } from 'vue';
import useGA from '@base/hooks/useGA';
import { useHeadway } from '@base/hooks';

export default defineComponent({
  name: 'HeaderHeadwayWidget',

  setup() {
    const openChangelog = () => {
      useGA('event', 'buttonChangeLogClick', 'menu-app');
    };

    const { hasUpdates, loadHeadwayScript, removeHeadwayScript } = useHeadway();

    onMounted(() => loadHeadwayScript({ openChangelog }));
    onUnmounted(removeHeadwayScript);

    return {
      hasUpdates,
      openChangelog,
    };
  },
});
</script>

<style lang="scss" module>
:global {
  #HW_badge_cont {
    position: absolute;
    right: 0;
    bottom: 0;

    > #HW_badge {
      visibility: hidden;
      background: transparent;
    }
  }
}

.updates {
  position: relative;

  &.newUpdate {
    color: var(--color-neutral-0);
    background-color: var(--color-brand-primary-400);
  }
}
</style>
