<script setup>
import { useI18n } from '@/locales';
import { useStore } from '@/store';
import { ref, useCssModule } from 'vue';
import { EMAIL_VERIFICATION } from '@/modules/account/constants';

import IconPulse from '@base/components/IconPulse/IconPulse.vue';
import UserEmailValidationForm from '@operators/components/UserEmailValidationForm/UserEmailValidationForm.vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  user: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close', 'message-feedback']);

const { t } = useI18n();
const store = useStore();
const style = useCssModule();
const loading = ref(false);
const startSendTimer = ref(false);
const showUserEmailValidationForm = ref(false);

const classes = {
  contentClasses: [style.container],
};

const handleResendEmailRequest = async (userEmail) => {
  try {
    const { links } = props.user;
    loading.value = true;

    if (userEmail !== props.user.email) {
      await store.dispatch('info/updateEmail', {
        url: links?.operatorPath,
        newEmail: { user: { email: userEmail } },
      });
    }
    await store.dispatch('info/resendEmail', { url: links?.sendConfirmationEmail });
    emit('message-feedback', 'info', t('account.emailVerificationModal.success'));
    startSendTimer.value = true;
  } catch (err) {
    emit('message-feedback', 'error', t('account.emailVerificationModal.failed'));
    throw err;
  } finally {
    loading.value = false;
  }
};

const emailNotReceivedAction = () => {
  showUserEmailValidationForm.value = true;
};
</script>

<template>
  <XModal
    :is-open="show"
    width="580"
    close-button
    v-bind="classes"
    data-testid="emailVerificationModal"
    @close="$emit('close')"
  >
    <IconPulse icon="envelope-circle-check"></IconPulse>
    <h4 :class="$style.title">
      {{ $t('account.emailVerificationModal.title') }}
    </h4>
    <p
      :class="$style.description"
      data-testid="emailInfoDescription"
    >
      {{
        $t('account.emailVerificationModal.description', {
          email: user.email,
        })
      }}
    </p>

    <template #actions>
      <UserEmailValidationForm
        v-if="showUserEmailValidationForm"
        :user="user"
        :is-loading="loading"
        :start-send-timer.sync="startSendTimer"
        :debounce-time-in-seconds="EMAIL_VERIFICATION.EMAIL_REQUEST_DEBOUNCE_TIME"
        :local-storage-key="EMAIL_VERIFICATION.RESEND_USER_EMAIL_MODAL"
        @resend-email-request="handleResendEmailRequest"
      />
      <XButton
        v-else
        design="text"
        data-testid="emailNotReceivedButton"
        :class="$style.actionButton"
        @click="emailNotReceivedAction"
      >
        {{ $t('account.emailVerificationModal.notReceived') }}
      </XButton>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .title {
    margin: var(--space-small-x) 0 var(--space-small-xx);
  }

  .description {
    margin-bottom: var(--space-medium);
    color: var(--color-neutral-800);
  }
}

.actionButton {
  margin-right: auto;
  margin-left: auto;
}
</style>
