<template>
  <XButton
    border-radius="circle"
    size="sm"
    design="text"
    data-testid="helpBaloonButton"
    @click="handleHelp"
  >
    <XIcon
      size="lg"
      icon="comment"
    />
  </XButton>
</template>

<script>
// TODO: TOGGLE_HUBSPOT_FORM_HELP_ENABLED
// Remove the line below
import { loadHubspot, openHubspotChat } from '@base/utils/hubspot';
// TODO: TOGGLE_HUBSPOT_FORM_HELP_ENABLED
// Remove useToggle below
import { useGA, useToggle } from '@base/hooks';
import { defineComponent } from 'vue';
import { useHubspotHelpForm } from '@operators/composables/useHubspotHelpForm';

export default defineComponent({
  name: 'HeaderHubspotWidget',
  props: {
    isNationalUser: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    // TODO: TOGGLE_HUBSPOT_FORM_HELP_ENABLED
    // Remove the line below
    loadHubspot();
    const { openHubspotHelpForm } = useHubspotHelpForm();
    // TODO: TOGGLE_HUBSPOT_FORM_HELP_ENABLED
    // Remove the line below
    const { isEnabled: isToggleHubspotFormHelpEnabled } = useToggle('toggleHubspotFormHelpEnabled');

    const handleHelp = () => {
      useGA('event', 'buttonHelpIconClick', 'menu-app');

      // TODO: TOGGLE_HUBSPOT_FORM_HELP_ENABLED
      // Remove the if statement and it's contents below
      if (props.isNationalUser && !isToggleHubspotFormHelpEnabled.value) {
        openHubspotChat();
        return;
      }

      openHubspotHelpForm();
    };

    return { handleHelp };
  },
});
</script>
